.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.roboto-sans-serif {
  font-family: "Roboto", sans-serif;
}

.pp-neue-montreal {
  font-family: "PP Neue Montreal", sans-serif;
}

.neue-power-sans-serif {
  font-family: "Neue Power", sans-serif;
}

.neue-bold {
  font-family: "Neue-bold";
}

.poppins-sans-serif {
  font-family: "Poppins", sans-serif;
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #fafafa;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

nav {
  position: fixed;
  left: 0px;
  width: 100%;
  top: 0px;
  padding: 1.125rem 0;
  background: #001316;
  z-index: 10000;
}
nav .container {
  display: flex;
  max-width: 1240px;
  margin: auto;
  align-items: center;
  justify-content: space-between;
}
nav .container .mobile-menu {
  display: none;
}
nav .container .nav--links {
  display: flex;
}
nav .container .nav--links .search-container {
  display: flex;
  align-items: center;
  position: relative;
}
nav .container .nav--links .search-container .search-icon {
  cursor: pointer;
  z-index: 1001;
  padding: 0.5rem;
  border: 1px solid #182e2e;
  border-radius: 6px;
}
nav .container .nav--links .search-container .search-icon-closed {
  border: none;
  z-index: 1001;
  padding-right: 0.5rem;
}
nav .container .nav--links .search-container .search-bar {
  display: flex;
  flex-direction: column;
  width: 0;
  opacity: 0;
  transition: max-height 0.3s ease-in, max-width 0.3s ease-in;
  position: absolute;
  top: 0;
  right: 30px;
  border-radius: 6px;
  z-index: 1000;
  overflow: hidden;
  border: 1px solid rgba(117, 255, 255, 0.4);
}
nav .container .nav--links .search-container .search-bar.expanded {
  width: 19.775rem;
  opacity: 1;
  right: 0;
}
nav .container .nav--links .search-container .search-bar .search-input {
  width: 100%;
  padding: 0.7rem 1.2rem;
  outline: none;
  font-weight: 400;
  font-size: 0.875rem;
  color: #fafafa;
  background-color: #0c2a2e;
  border: none;
}
nav .container .nav--links .search-container .search-bar .search-results {
  padding: 10px;
  background-color: #001316;
  color: #fffaf7;
  overflow-y: scroll;
  max-height: 345px;
  -ms-overflow-style: none;
  scrollbar-width: thin;
}
nav .container .nav--links .search-container .search-bar .search-results::-webkit-scrollbar {
  display: none;
}
nav .container .nav--links .search-container .search-bar .search-results .filter-container {
  display: flex;
  justify-content: start;
  padding: 1rem;
  position: sticky;
  top: -10px;
  background-color: #001316;
}
nav .container .nav--links .search-container .search-bar .search-results .filter-container .filter-button.active {
  background-color: #75ffff;
  color: #001316;
}
nav .container .nav--links .search-container .search-bar .search-results .filter-container .filter-button {
  padding: 0.375rem 1.375rem;
  cursor: pointer;
  border-radius: 16px;
  background-color: #ebebeb;
  color: #001316;
  font-size: 0.875rem;
  font-weight: 400;
  border: 1px solid #233737;
  font-family: "neueMontreal-Book";
}
nav .container .nav--links .search-container .search-bar .search-results .category-title {
  display: flex;
  margin-top: 1.188rem;
  margin-bottom: -1rem;
  width: 1.5rem;
  gap: 8px;
}
nav .container .nav--links .search-container .search-bar .search-results .category-title img {
  opacity: 50%;
}
nav .container .nav--links .search-container .search-bar .search-results .category-title span {
  color: grey;
  opacity: 90%;
}
nav .container .nav--links .search-container .search-bar .search-results .category-item {
  padding: 1.313rem 0;
  border-bottom: 1px solid rgba(129, 129, 129, 0.19);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav .container .nav--links .search-container .search-bar .search-results .category-item img {
  width: 1.688rem;
}
nav .container .nav--links .search-container .search-bar .search-results .category-item p {
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 1rem;
  padding-top: 0.1rem;
  color: #fffaf7;
}
nav .container .nav--links .search-container .search-bar .search-results .category-item .item-type {
  color: #777;
  font-size: 0.9em;
  margin-left: auto;
}
nav .links {
  display: flex;
  align-items: center;
  margin-top: 0 !important;
}
nav .links li {
  list-style-type: none !important;
}
nav .links li a {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: #fafafa;
  margin-left: 1.3rem;
  font-family: "neuePowerTrial-Medium";
}
nav .links li span {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: #fafafa;
  margin-left: 2rem;
  font-family: "neuePowerTrial-Medium";
}
nav .login--sec {
  display: flex;
  align-items: center;
  margin-left: 1.4rem;
}
nav .login--sec a {
  margin-left: 1.4rem;
  font-family: "neuePowerTrial-Regular";
}
nav .login--sec .search {
  padding: 0.625rem;
  border-radius: 6px;
  background: #e1e6ef;
  display: flex;
}

@media (max-width: 900px) {
  nav {
    padding: 1.125rem 1rem;
  }
  nav .container .mobile-menu {
    display: flex;
    align-items: center;
  }
  nav .container .mobile-menu .search-container {
    width: 100%;
  }
  nav .container .mobile-menu .search-container .overlay {
    display: none;
    position: fixed;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    /* Blur effect */
    z-index: 999;
  }
  nav .container .mobile-menu .search-container .search-icon {
    border: 1px solid #182e2e;
    border-radius: 6px;
    position: relative;
    padding: 0.3rem;
    right: 50%;
  }
  nav .container .mobile-menu .search-container.search-active .overlay {
    display: block;
    top: 8rem;
  }
  nav .container .mobile-menu .search-container .search-icon-closed {
    border: 1px solid #182e2e;
    border-radius: 6px;
    z-index: 1001;
    position: relative;
    padding: 0.3rem;
    right: 50%;
    background-color: #0c2a2e;
  }
  nav .container .mobile-menu .search-container .search-bar {
    display: flex;
    flex-direction: column;
    width: 0;
    opacity: 0;
    transition: max-height 0.3s ease-in, max-width 0.4s ease-in;
    background-color: rgba(12, 42, 46, 0.9);
    position: absolute;
    left: 0;
    height: 100;
    top: 20%;
    right: 30px;
    z-index: 1000;
    overflow: hidden;
  }
  nav .container .mobile-menu .search-container .search-bar.expanded {
    width: 100%;
    opacity: 1;
    top: 100%;
  }
  nav .container .mobile-menu .search-container .search-bar.expanded .expand-icon {
    cursor: pointer;
    position: absolute;
    left: 1rem;
    width: 1.5rem;
    z-index: 1001;
    top: 15px;
  }
  nav .container .mobile-menu .search-container .search-bar .search-input {
    width: 100%;
    padding: 1.2rem 3.2rem;
    outline: none;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 0.875rem;
    color: #fafafa;
    background-color: #0c2a2e;
    border: none;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results {
    padding: 10px;
    background-color: #001316;
    color: #fffaf7;
    overflow-y: scroll;
    max-height: 345px;
    -ms-overflow-style: none;
    scrollbar-width: thin;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results::-webkit-scrollbar {
    display: none;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .filter-container {
    display: flex;
    justify-content: start;
    padding-top: 0.5rem;
    position: sticky;
    top: -10px;
    background-color: #001316;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .filter-container .filter-button.active {
    color: #001316;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .filter-container .filter-button {
    padding: 0.375rem 1.375rem;
    cursor: pointer;
    border-radius: 16px;
    background-color: #ebebeb;
    color: #001316;
    margin-left: 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #233737;
    font-family: "neueMontreal-Book";
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-title {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: -1rem;
    padding: 5px;
    width: 1.5rem;
    gap: 8px;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-title img {
    opacity: 50%;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-title span {
    color: grey;
    opacity: 90%;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-item {
    padding: 1.313rem 0;
    border-bottom: 1px solid rgba(129, 129, 129, 0.19);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-item img {
    width: 1.688rem;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-item p {
    font-size: 0.875rem;
    font-weight: 400;
    padding-left: 1rem;
    padding-top: 0.1rem;
    color: #fffaf7;
  }
  nav .container .mobile-menu .search-container .search-bar .search-results .category-item .item-type {
    color: #777;
    font-size: 0.9em;
    margin-left: auto;
  }
  nav .container .mobile-menu a {
    margin-left: 1rem;
    display: block;
  }
  nav .container .nav--links {
    display: none;
  }
}/*# sourceMappingURL=navbar.css.map */
